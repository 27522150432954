<template>
    <sections>
        <section-cards
            color="bg-zinc-100"
            buttonText="Browse Jobs"
            buttonRoute="/jobs"
            :cards="[
                {
                    route: '/jobs',
                    image: $config.public.images + 'driving-jobs.jpg',
                    imageAspectRatio: 'aspect-[352/185]',
                    imageLoading: 'eager',
                    imagePriority: 'high',
                    heading: 'Driving Jobs',
                    excerpt: 'We are currently recruiting for driving jobs including: HGV Class 1, HGV Class 2, HIAB, Moffett and Multi Drop.'
                },
                {
                    route: '/jobs',
                    image: $config.public.images + 'industrial-jobs.jpg',
                    imageAspectRatio: 'aspect-[352/185]',
                    imageLoading: 'eager',
                    heading: 'Industrial Jobs',
                    excerpt: 'Currently recruiting for jobs including: Assembly Operatives, Labourers, Warehouse Operatives, and Team Leaders.'
                },
                {
                    route: '/jobs',
                    image: $config.public.images + 'office-professional-jobs.jpg',
                    imageAspectRatio: 'aspect-[352/185]',
                    heading: 'Office & Professional',
                    excerpt: 'We are currently recruiting for office and professional jobs including: Administrators, Data Entry, Project Support, Sales and Office Managers.'
                },
            ]"
        ></section-cards>
        <section-text
            heading="Freeway Recruitment Leeds | Recruitment Manchester"
            content="<article-home />"
        ></section-text>
        <section-text
            color="bg-zinc-50"
            heading="Looking for a job?"
            content="<p>We have vacancies, contact us today.</p>"
            buttonText="Contact Us"
            buttonRoute="/contact"
            :textCentered="false"
        ></section-text>
    </sections>
</template>

<script setup>
const $config = useRuntimeConfig();
useSeo().buildHead({
    title: 'Freeway Recruitment Leeds | Recruitment Manchester',
    description: 'Freeway Recruitment specialise in the placement of temporary and permanent staff in Driving, Industrial, Engineering and Commercial sectors. Call us today.',
    index: true,
});
</script>
